import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Link as GatsbyLink } from "gatsby"
import { isGatsbyLink } from "./helpers"

import styles from "./styles.module.scss"

const Link = ({ activeClassName, activeStyle, children, className, onClick, target, to }) => {
  const classes = classNames(styles.link, {
    [className]: className
  })

  let link

  if (isGatsbyLink(to)) {
    link = (
      <GatsbyLink
        to={to}
        className={classes}
        activeStyle={activeStyle}
        activeClassName={activeClassName}
        onClick={onClick && onClick.bind(this)}
      >
        {children}
      </GatsbyLink>
    )
  } else {
    link = (
      <a
        href={to}
        target={target}
        rel={target === "_blank" ? "noopener" : ""}
        className={classes}
        onClick={onClick && onClick.bind(this)}
      >
        {children}
      </a>
    )
  }

  return link
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  to: PropTypes.string.isRequired
}

Link.defaultProps = {
  target: "_blank"
}

export default Link
