import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-scroll"
import classNames from "classnames"
// import FontFaceObserver from "fontfaceobserver"

import styles from "./styles.module.scss"

const Navigation = ({ className, links = [] }) => {
  // ------------------------------------------------------ | Check if fonts are loaded

  const acuminProFontIsLoaded = true

  // useEffect(() => {
  //   const acuminProFont = new FontFaceObserver("acumin-pro")

  //   acuminProFont.load().then(function () {
  //     setAcuminProFont(true)
  //   })
  // })

  const classes = classNames(styles.navigation, className, `is-hidden`, {
    [[`animate__animated animate__fadeIn`]]: acuminProFontIsLoaded
  })

  return (
    <nav className={classes}>
      <div className={styles.navigation_container}>
        <ul>
          {links.map((item, index) => {
            return (
              <li key={index}>
                <Link
                  activeClass={styles.is_active}
                  to={item.url}
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  {item.label}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  )
}

Navigation.propTypes = {
  /**
   * CSS class(es) applied to the wrapping element
   */
  className: PropTypes.string,
  /**
   * An array of link objects used to build the list
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
}

Navigation.defaultProps = {}

export default Navigation
