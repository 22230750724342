import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Helmet } from "react-helmet"

import Header from "./header"
import { DebugMediaQueries } from "@plugins/gatsby-ample-debuggers"

const Layout = ({ children, className }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />

        <link
          rel="preload"
          href="https://d1tmclqz61gqwd.cloudfront.net/fonts/SentinelSSm-BookWeb.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        ></link>

        <link rel="stylesheet" href="https://use.typekit.net/kuk6fjo.css" media="all" />
        <link rel="stylesheet" href="fonts/font-sentinel.css" media="all" />
      </Helmet>

      <Header />

      <main
        className={classNames({
          [className]: className
        })}
      >
        {children}
      </main>

      <DebugMediaQueries isShowing={process.env.GATSBY_DEBUG_MEDIA_QUERIES} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Additional classes on the main element.
   */
  className: PropTypes.string
}

export default Layout
