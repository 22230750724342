import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
// import FontFaceObserver from "fontfaceobserver"

import { useMediaQuery } from "react-responsive"

import Link from "@src/components/link"
import SVG from "@src/components/svg"

import Navigation from "./navigation"

import styles from "./styles.module.scss"

const Header = ({ main_navigation }) => {
  // ------------------------------------------------------ | Check if fonts are loaded

  // const [acuminProFontIsLoaded, setAcuminProFont] = useState(false)
  const acuminProFontIsLoaded = true

  // useEffect(() => {
  //   const acuminProFont = new FontFaceObserver("acumin-pro")

  //   acuminProFont.load().then(function () {
  //     setAcuminProFont(true)
  //   })
  // })

  // ------------------------------------------------------ | Show / Hide Header on Scroll

  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const win = typeof window === "undefined" ? undefined : window

    let prevScroll = win.scrollY || document.documentElement.scrollTop
    let position
    let direction = 0
    let prevDirection = 0

    const checkScroll = () => {
      position = win.scrollY || document.documentElement.scrollTop
      if (position > prevScroll) {
        //scrolled up
        direction = 2
      } else if (position < prevScroll) {
        //scrolled down
        direction = 1
      }

      if (direction !== prevDirection) {
        toggleHeader(direction, position)
      }

      prevScroll = position
    }

    const toggleHeader = (direction, position) => {
      if (direction === 2 && position > 95) {
        setVisible(false)
        prevDirection = direction
      } else if (direction === 1) {
        setVisible(true)
        prevDirection = direction
      }
    }

    win.addEventListener("scroll", checkScroll)
  }, [])

  // ------------------------------------------------------ | Classes

  const classes = classNames(styles.header, {
    [styles.is_visible]: visible,
    [styles.is_hidden]: !visible
  })

  // ------------------------------------------------------ | Share Button

  const [shareMenuToggle, setShareMenuToggle] = useState(false)

  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText("https://annual-report.crossroads.net/")
    setCopyToClipboardSuccess(!copyToClipboardSuccess)
    setShareMenuToggle(!shareMenuToggle)
    setTimeout(() => {
      setCopyToClipboardSuccess(copyToClipboardSuccess)
    }, 5000)
  }

  // ------------------------------------------------------ | Annual Report Logo

  const smallDown = useMediaQuery({ query: "(max-width: 44.938em)" })
  const small = useMediaQuery({ query: "(min-width: 45em)" })

  // ------------------------------------------------------ | Component

  return (
    <header className={classes}>
      <div
        className={classNames(styles.top, `is-hidden`, `animate__animated `, {
          [[`animate__fadeIn`]]: acuminProFontIsLoaded
        })}
      >
        <div aria-label="2020 Annual Report">
          {small && <SVG name="logo-annual-report-large" />}

          {smallDown && <SVG name="logo-annual-report-small" />}
        </div>

        <Link to="https://www.crossroads.net" className={styles.logo}>
          <span>Crossroads Church Logo</span>
          <SVG name="logo" />
        </Link>

        <div className={styles.buttons}>
          {shareMenuToggle ? (
            <div className={shareMenuToggle ? styles.share_button_expanded : null}>
              <Link
                to="https://www.facebook.com/sharer/sharer.php?u=https://annual-report.crossroads.net/"
                onClick={() => setShareMenuToggle(!shareMenuToggle)}
              >
                <SVG name="icon-facebook" />
              </Link>
              <Link
                to="https://twitter.com/intent/tweet?url=https%3A%2F%2Fannual-report.crossroads.net%2F&text=2020%20Annual%20Report"
                onClick={() => setShareMenuToggle(!shareMenuToggle)}
              >
                <SVG name="icon-twitter" />
              </Link>
              <button onClick={() => copyToClipboard()}>
                <SVG name="link" />
              </button>

              <button className={styles.close} onClick={() => setShareMenuToggle(!shareMenuToggle)}>
                <SVG name="close-thin" />
              </button>
            </div>
          ) : (
            <button
              className={styles.share_button}
              onClick={() => setShareMenuToggle(!shareMenuToggle)}
            >
              <SVG className={styles.close} name="share" />
              <span>Share</span>
            </button>
          )}

          <Link className={styles.give_now_button} to="https://pushpay.com/g/crossroads">
            <SVG name="give" />
            <span>Give</span>
          </Link>
        </div>
      </div>

      <div className={styles.navigation_container}>
        <Navigation
          className={styles.main_navigation}
          links={main_navigation}
          fontIsLoaded={acuminProFontIsLoaded}
        />
        {copyToClipboardSuccess ? (
          <div className={styles.clipboard_success}>
            <p>annual-report.crossroads.net has been copied to your clipboard!</p>
          </div>
        ) : null}
      </div>
    </header>
  )
}
Header.propTypes = {
  /**
   * An array of main navigation links.
   * This prop is passed to the LinkList component
   */
  main_navigation: PropTypes.array.isRequired
}

Header.defaultProps = {}

export default Header
