import React from "react"

import Component from "./component"
import fixtures from "./navigation/fixtures"

const Header = () => {
  return <Component {...fixtures.default} />
}

export default Header

export { Component as component, fixtures }
