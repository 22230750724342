export default {
  default: {
    main_navigation: [
      { url: "adventurous-life", label: "Adventurous Life" },
      { url: "embark-and-lead", label: "Embark & Lead" },
      { url: "blazing-new-trails", label: "Blazing New Trails" },
      { url: "learn-from-trial", label: "Learn from Trial" },
      { url: "count-the-cost", label: "Count the Cost" },
      { url: "eyes-on-the-summit", label: "Eyes on the Summit" }
    ]
  }
}
